import React, { useState, useRef, useEffect, Fragment } from "react";
import { Modal, Button } from 'react-bootstrap'
import { usePaymentTypes } from "../Contexts/PaymentTypesContext";
import { subscribe, unsubscribe } from "../ICheck/ICheckEventEmitter";

const SelectPaymentTypeStep = ({ onNext, onHide }) => {
    const paymentErrorAlert = useRef(null);
    const paymentTypes = usePaymentTypes();

    const [selectedPaymentType, setSelectedPaymentType] = useState(null);
    const [isIframeLoading, setIsIframeLoading] = useState(true);
    const [paymentHadError, setPaymentHadError] = useState(false);
    const [paymentError, setPaymentError] = useState('');

    const handlePaymentTypeSelected = (event) => {
        setPaymentHadError(false);
        setIsIframeLoading(true);
        setSelectedPaymentType(paymentTypes.find(paymentType => paymentType.id === event.target.value) || null);
    }

    const handleIframeLoaded = () => {
        console.log('iframe loaded');
        setIsIframeLoading(false);
    }

    const handleFinishedICheckProcessing = (iCheckResult) => {
        setPaymentHadError(false);

        if (!iCheckResult.validationResult.approved) {
            setPaymentError(iCheckResult.denialReason);
            setPaymentHadError(true);
            return;
        }

        onNext(iCheckResult.paymentMethod);
    };

    const renderIframeLoader = () => {
        if (isIframeLoading) {
            return (
                <div className={`mt-4 icheck-iframe-loader ${selectedPaymentType.id}`}>
                    <i className="spinner spinner-border"></i>
                </div>
            );
        }
    }

    useEffect(() => {
        subscribe(handleFinishedICheckProcessing);
        return () => unsubscribe(handleFinishedICheckProcessing);
    }, []);

    useEffect(() => {
        if (paymentHadError && paymentErrorAlert.current !== null)
            paymentErrorAlert.current.scrollIntoView();
    }, [paymentHadError]);

    return (
        <Fragment>
            <Modal.Header>
                <h3 className="modal-title">
                    Add New Payment Method
                </h3>
                <button className="btn-close" onClick={onHide}></button>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group">
                    <select className="form-control form-select" defaultValue="none" onChange={handlePaymentTypeSelected}>
                        <option value="none" disabled>Select Payment Type</option>
                        {paymentTypes.map((paymentType) =>
                            <option key={paymentType.id} value={paymentType.id}>{paymentType.name}</option>
                        )}
                    </select>
                    {paymentHadError &&
                        <div className="alert alert-danger mt-4" ref={paymentErrorAlert}>
                            {paymentError}
                        </div>
                    }
                    {selectedPaymentType !== null &&
                        <>
                            {renderIframeLoader()}
                            <div className="icheck-iframe-wrapper mt-4" style={{ display: !isIframeLoading ? 'flex' : 'none' }}>
                                <iframe className={`js-icheck-iframe icheck-iframe ${selectedPaymentType.id}`} src={selectedPaymentType.iframeUrl} onLoad={handleIframeLoaded}></iframe>
                            </div>
                        </>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Fragment>
    );
}

export default SelectPaymentTypeStep;