import React, { Fragment } from "react";
import PaymentMethodsTable from "./PaymentMethodsTableComponent";
import Autopay from "./AutopayComponent";

const PaymentMethods = () => {
    return (
        <Fragment>
            <PaymentMethodsTable />
            <Autopay />
        </Fragment>
    );
}

export default PaymentMethods;