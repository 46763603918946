import React from "react";
import { Modal, Button } from 'react-bootstrap'

const GenericErrorModal = ({onHide, onFinished, ...props}) => {
    return (
        <Modal
            {...props}
            className="payment-and-billing-modal"
            onExited={onFinished}
        >
            <Modal.Body>
                <button type="button" className="close" onClick={onHide}></button>
                <h3 className="modal-title">Something went wrong - please try again later and contact us if the problem persists.</h3>
                <div className="d-flex w-100 justify-content-end">
                    <Button variant="primary" onClick={onHide}>Close</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default GenericErrorModal;