import React, { createContext, useContext, useState } from "react";

const ModalContext = createContext(null);

export const ModalProvider = ({children}) => {
    const show = (component, props) => {
        setModalState(prevState => ({
            ...prevState,
            isHidden: false,
            component: component,
            props: props || {}
        }));
    }

    const hide = () => {
        setModalState(prevState => ({
            ...prevState,
            isHidden: true,
            props: {}
        }));
    }

    const exit = () => {
        setModalState(prevState => ({
            ...prevState,
            component: null,
            props: {},
            isHidden: true,
        }));
    }

    const [modalState, setModalState] = useState({
        component: null,
        props: {},
        isHidden: true,
        show: show,
        hide: hide,
        exit: exit
    });

    return (
        <ModalContext.Provider value={modalState}>
          {children}
        </ModalContext.Provider>
      );
}

export const useModals = () =>
    useContext(ModalContext);
