import React, { useState } from "react";
import { Modal, Button } from 'react-bootstrap'
import axios from 'axios';
import getRollbar from "../../globals/rollbar";

const DeletePaymentMethodModal = ({ methods, selectedMethod, onHide, onFinished, ...props }) => {
    const [isProcessing, setIsRequestInFlight] = useState(false);
    const [result, setResult] = useState({
        wasCancelled: true,
        hadError: false,
        methods: null
    });

    const handleMethodSelected = (event) => {

    }

    const handleConfirm = () => {
        setIsRequestInFlight(true);

        axios.delete(`/api/paymentmethods/delete/${selectedMethod.token}`).then(res => {
            setResult({
                hadError: false,
                wasCancelled: false,
                methods: res.data
            });
        }).catch(err => {
            console.error(err);
            getRollbar().error(`DeletePaymentMethodModal: Received an error response when deleting method ${selectedMethod.token}. 
                Response: ${JSON.stringify(err)}`);
            setResult({
                hadError: true,
                wasCancelled: true,
                methods: null
            });
        }).finally(() => onHide());
    }

    const handleExited = () => {
        setIsRequestInFlight(false);
        onFinished(result);
    }

    const renderAutopayWarning = (selectedMethod) => {
        if(selectedMethod == null || !selectedMethod.isAutopay || methods == null || methods.length > 1)
            return;

        return (
            <p>
                The selected payment method is currently used for Autopay. If you remove it, Autopay will be turned off. Please setup Autopay with a new payment method.
            </p>
        )
    }

    const renderMethodSelection = (selectedMethod) => {
        if(selectedMethod == null || !selectedMethod.isAutopay || methods == null || methods.length <= 1)
            return;

        return (
            <ul className="payment-methods-list">
                {methods.map((method, index) => 
                    <li key={method.token}>
                        <input type="radio" id={`method-${index}`} name="newDefaultMethod" onChange={handleMethodSelected} />
                        <label htmlFor={`method-${index}`} className="ms-3 font-weight-bold">{method.name}</label>
                    </li>
                )}
            </ul>
        )
    }

    return (
        <Modal
            {...props}
            className="payment-and-billing-modal"
            onExited={handleExited}
        >
            <Modal.Header>
                <h3 className="modal-title">
                    Remove Payment Method?
                </h3>
                <button className="btn-close" onClick={onHide} disabled={isProcessing}></button>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to remove the payment method <strong>{selectedMethod?.name}</strong>?</p>
                {renderAutopayWarning(selectedMethod)}
                {/*renderMethodSelection(selectedMethod)*/}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleConfirm} disabled={isProcessing}>
                    {isProcessing && <span className="js-spinner spinner spinner-border align-middle me-1"></span>}
                    Yes
                </Button>
                <Button variant="secondary" onClick={onHide} disabled={isProcessing}>
                    No
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DeletePaymentMethodModal;