import React, { useState } from "react";
import { Modal, Button } from 'react-bootstrap'
import axios from 'axios';
import getRollbar from "../../globals/rollbar";

const TurnOnPaperlessBillingModal = ({ onHide, onFinished, ...props }) => {
    const [isProcessing, setIsRequestInFlight] = useState(false);
    const [result, setResult] = useState({
        hadError: false,
        isPaperlessOn: false
    });

    const handleConfirm = () => {
        setIsRequestInFlight(true);

        axios.post('/api/accountinfo/SetPaperlessBillingEnrollmentStatus', {
            paperlessBillingEnrollment: true
        }).then(_ => {
            setResult({
                hadError: false,
                isPaperlessOn: true
            });
        }).catch(err => {
            console.error(err);
            getRollbar().error(`TurnOnPaperlessBillingModal: Received an error response when turning on Paperless Billing. 
                Response: ${JSON.stringify(err)}`);
            setResult({
                hadError: true,
                isPaperlessOn: false
            });
        }).finally(() => onHide());
    }

    const handleExited = () => {
        setIsRequestInFlight(false);
        onFinished(result);
    }

    return (
        <Modal
            {...props}
            className="payment-and-billing-modal"
            onExited={handleExited}
        >
            <Modal.Header>
                <h3 className="modal-title">
                    Turn On Paperless Billing?
                </h3>
                <button className="btn-close" onClick={onHide} disabled={isProcessing}></button>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to turn on Paperless Billing?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleConfirm} disabled={isProcessing}>
                    {isProcessing && <span className="js-spinner spinner spinner-border align-middle me-1"></span>}
                    Yes, turn on Paperless Billing
                </Button>
                <Button variant="secondary" onClick={onHide} disabled={isProcessing}>
                    No, keep it off
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default TurnOnPaperlessBillingModal;