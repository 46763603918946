import React from 'react';
import getRollbar from '../../globals/rollbar';

export class ErrorBoundary extends React.Component
{
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {  
        return { hasError: true };  
    }

    componentDidCatch(error, errorInfo) {  
        getRollbar().error(`PaymentAndBilling: Unrecoverable error ${error}, info: ${JSON.stringify(errorInfo)}`);
        console.error(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {      
            return <section className="account-information payment-methods">
                <h2>Something went wrong.</h2>
                <p>Please try <a href="#" onClick={() => window.location.reload()}>reloading the page</a> and contact us if the problem persists.</p>
            </section>;    
        }

        return this.props.children; 
    }
}