import React, { useState } from "react";
import axios from 'axios';
import GenericErrorModal from "./Modals/GenericErrorModal";
import TurnOnPaperlessBillingModal from "./Modals/TurnOnPaperlessBillingModal";
import { useModals } from "./Contexts/ModalContext";
import getRollbar from "../globals/rollbar";

//Needed for interoperability with the rest of page
const congratsModal = document.querySelector('.modal.js-congrats-paperless-modal');
const learnMoreModal = document.querySelector('.modal.js-learn-more-paperless-modal');

const handleLearnMore = () => $(learnMoreModal).modal('show'); 

const PaperlessBilling = ({ showToggle, enabled }) => {
    const modals = useModals();
    const [paperlessEnabled, setPaperlessEnabled] = useState(enabled);
    const [showPaperlessToggle, setShowPaperlessToggle] = useState(showToggle);
    const [requestInFlight, setRequestInFlight] = useState(false);

    const showTurnOnModal = () => {
        const turnOnModal = <TurnOnPaperlessBillingModal centered backdrop="static" 
            keyboard={false} onFinished={handleTurnOn} />

        modals.show(turnOnModal);
    }

    const showErrorModal = () => {
        modals.show(<GenericErrorModal centered />);
    }

    const handleToggleClicked = () => {
        if (!paperlessEnabled) {
            showTurnOnModal();
        } else {
            handleTurnOff();
        }
    }

    const handleTurnOff = () => {
        setRequestInFlight(true);

        axios.post('/api/accountinfo/SetPaperlessBillingEnrollmentStatus', {
            paperlessBillingEnrollment: !paperlessEnabled
        }).then(_ => {
            setPaperlessEnabled(!paperlessEnabled);
            $(congratsModal).modal('show');
        }).catch(err => {
            console.error(err);
            getRollbar().error(`PaperlessBilling: Received an error response when turning off Paperless Billing. 
                Response: ${JSON.stringify(err)}`);
            showErrorModal();
        }).finally(() => {
            setRequestInFlight(false);
        });
    }

    const handleTurnOn = (result) => {
        if (result.hadError){
            showErrorModal();
            return;
        }

        setPaperlessEnabled(result.isPaperlessOn);
    }

    return (
        <section className="account-information paperless-billing">
            <div className="row">
                <div className="col-12 col-lg-10">
                    <h3>Paperless billing</h3>
                    <p>We're always looking for ways to make your life easier while helping the environment. Your bill may be viewed in My Account. If you would like to receive paper bills via mail, please call customer care at (833) 667-6468.</p>
                </div>
                {showPaperlessToggle && (
                    <div className="col-12 col-lg-2">
                        <div className="d-lg-flex justify-content-end">
                            {requestInFlight
                                ? <div className="js-enable-spinner spinner spinner-border" ></div>
                                : <input name="paperless-enabled" className="on-off-switch mb-0" type="checkbox" checked={paperlessEnabled} onChange={handleToggleClicked} />}
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
}

export default PaperlessBilling;