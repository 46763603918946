import React, { useState } from "react";
import axios from 'axios';
import GenericErrorModal from "./Modals/GenericErrorModal";
import { usePaymentMethods, usePaymentMethodsDispatch } from "./Contexts/PaymentMethodsContext";
import TurnOffAutoPayModal from "./Modals/TurnOffAutoPayModal";
import { useModals } from "./Contexts/ModalContext";
import EnableAutopayModal from "./Modals/EnableAutopayModal";
import getRollbar from "../globals/rollbar";

//Needed for interoperability with the rest of page
const congratsModal = document.querySelector('.modal.js-congrats-autopay-modal');
const learnMoreModal = document.querySelector('.modal.js-learn-more-autopay-modal');

const handleLearnMore = () => $(learnMoreModal).modal('show'); 

const updatePayBillButton = (enabled) => {
    document.querySelectorAll('.btn-pay-bill').forEach(btn => {
        if (enabled) {
            btn.dataset.autopayEnrolled = '';
        } else {
            delete btn.dataset.autopayEnrolled;
        }
    });
}

const Autopay = () => {
    const modals = useModals();
    const paymentMethodsContext = usePaymentMethods();
    const paymentMethodsDispatch = usePaymentMethodsDispatch();
    const [requestInFlight, setRequestInFlight] = useState(false);

    const showErrorModal = () =>
        modals.show(<GenericErrorModal centered />);
    
    const handleToggleClicked = () => {
        if(paymentMethodsContext.autopayMethod != null) {
            const turnOffModal = <TurnOffAutoPayModal centered backdrop="static" 
                keyboard={false} selectedMethod={paymentMethodsContext.autopayMethod} onFinished={handleTurnOff} />

            modals.show(turnOffModal);
            return;
        }

        if(paymentMethodsContext.defaultMethod != null) {
            handleTurnOn();
            return;
        }

        const addOrSelectModal = <EnableAutopayModal centered backdrop="static" keyboard={false} 
            methods={paymentMethodsContext.methods} onFinished={handleAddOrSelect} />
        modals.show(addOrSelectModal);
    }

    const handleTurnOn = () => {
        setRequestInFlight(true);

        axios.post('/api/paymentmethods/setautopay', {
            walletId: paymentMethodsContext.defaultMethod.token,
            autopayEnabled: true,
            action: "on"
        }).then(res => {
            paymentMethodsDispatch({
                type: 'updated', 
                method: res.data
            });
            updatePayBillButton(true);
            $(congratsModal).modal('show');
        }).catch(err => {
            console.error(err);
            getRollbar().error(`Autopay: Received an error response when setting Autopay on method ${paymentMethodsContext.defaultMethod.token}. 
                Response: ${JSON.stringify(err)}`);
            showErrorModal();
        }).finally(() => {
            setRequestInFlight(false);
        });
    }

    const handleTurnOff = (result) => {
        if (result.hadError){
            showErrorModal();
            return;
        }

        if (!result.wasCancelled){
            paymentMethodsDispatch({
                type: 'updated', 
                method: result.method
            });

            updatePayBillButton(false);
        }
    }

    const handleAddOrSelect = (result) => {
        if (result.hadError){
            showErrorModal();
            return;
        }

        if (!result.wasCancelled){
            updatePayBillButton(true);

            if(result.wasMethodAdded){
                paymentMethodsDispatch({
                    type: 'added', 
                    methods: result.data
                });
            } else {
                paymentMethodsDispatch({
                    type: 'updated', 
                    method: result.data
                });
            }

            $(congratsModal).modal('show');
        }
    }

    return (
        <section className="account-information auto-pay">
            <div className="row">
                <div className="col-12 col-lg-10">
                    <h3>Auto Pay</h3>
                    <p>
                        Sign up for Auto Pay to automatically charge your default payment monthly. You'll enjoy the peace of mind that comes with knowing your account is always in good standing!
                        {' '}
                        <strong>Any due bills will be paid immediately upon enrollment.</strong>
                        {' '}
                        <a href="#" className="js-open-autopay-blurb" onClick={handleLearnMore}>Learn more</a>
                    </p>
                </div>
                <div className="col-12 col-lg-2">
                    <div className="d-lg-flex justify-content-end">
                        {requestInFlight || paymentMethodsContext.loading
                            ? <div className="js-enable-spinner spinner spinner-border" ></div>
                            : <input name="autopay-enabled" className="on-off-switch mb-0" type="checkbox" disabled={paymentMethodsContext.loading} 
                                checked={paymentMethodsContext.autopayMethod !== null} onChange={handleToggleClicked} />}
                    </div>
                </div>
                {paymentMethodsContext.autopayMethod !== null &&
                    <div className="mt-4 mt-lg-0 js-existing-payment-method">
                        <h5>Payment Method</h5>
                        <div>Your payment method is <span className="js-active-payment-method-name font-weight-bold">{paymentMethodsContext.autopayMethod.name}</span></div>
                        <div>Ending in <span className="js-active-payment-method-number font-weight-bold">{paymentMethodsContext.autopayMethod.cardEnd}</span></div>
                        <div className="mb-2">
                            Expires on <span className="js-active-payment-method-expiry font-weight-bold">{paymentMethodsContext.autopayMethod.expirationDate}</span>
                        </div>
                    </div>
                }
            </div>
        </section>
    );
}

export default Autopay;