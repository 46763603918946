import React, { useState } from "react";
import { Modal, Button } from 'react-bootstrap'
import AutopaySelectMethodStep from "../Steps/AutopaySelectMethodStep";
import SelectPaymentTypeStep from "../Steps/SelectPaymentTypeStep";
import ConfirmAddPaymentMethodStep from "../Steps/ConfirmAddPaymentMethodStep";
import ConfirmSetAutopayMethodStep from "../Steps/ConfirmSetAutopayMethodStep";

const EnableAutopayModal = ({ defaultMethod, methods, onHide, onFinished, ...props }) => {
    const [result, setResult] = useState({
        hadError: false,
        wasCancelled: true,
        wasMethodAdded: false,
        data: null
    });

    const handleFinished = (result) => {
        setResult({
            wasCancelled: false,
            hadError: false,
            wasMethodAdded: result.wasMethodAdded,
            data: result.data
        });

        onHide();
    }

    const handleHide = () => 
        onHide();

    const handleError = () => {
        setResult({
            wasCancelled: false,
            hadError: true,
            wasMethodAdded: false,
            data: null
        });

        onHide();
    }

    const handleExited = () => 
        onFinished(result);

    const handleCreatePaymentMethod = () => {
        setCurrentStep(<SelectPaymentTypeStep onHide={handleHide} onNext={handlePaymentMethodCreated} />);
    }   

    const handlePaymentMethodSelected = (paymentMethod) => {
        setCurrentStep(<ConfirmSetAutopayMethodStep stepData={paymentMethod} onHide={handleHide} 
            onError={handleError} onFinished={handleFinished} showSaveOnly={false} />);
    }

    const handlePaymentMethodCreated = (paymentMethod) => {
        setCurrentStep(<ConfirmAddPaymentMethodStep stepData={paymentMethod} onHide={handleHide} 
            onError={handleError} onFinished={handleFinished} showSaveOnly={false} />);
    }

    const [currentStep, setCurrentStep] = useState(methods == null || methods.length == 0 
        ?   <SelectPaymentTypeStep onHide={handleHide} onNext={handlePaymentMethodCreated} />
        :   <AutopaySelectMethodStep onHide={handleHide} onSelected={handlePaymentMethodSelected} 
                onCreate={handleCreatePaymentMethod} methods={methods} />
    );

    return (
        <Modal
            {...props}
            className="payment-and-billing-modal"
            onExited={handleExited}
        >
            {currentStep}
        </Modal>
    );
}

export default EnableAutopayModal;