import React, { Fragment, useState } from "react";
import { Modal, Button } from 'react-bootstrap'
import axios from 'axios';
import getRollbar from "../../globals/rollbar";

const ConfirmSetAutopayMethodStep = ({ stepData, onHide, onError, onFinished }) => {
    const [isProcessing, setIsProcessing] = useState(false);

    const handleSetMethod = () => {
        setIsProcessing(true);

        axios.post('/api/paymentmethods/setautopay', {
            walletId: stepData.token,
            autopayEnabled: true,
            action: "on"
        }).then(res => {
            onFinished({
                wasMethodAdded: false,
                data: res.data
            });
        }).catch(err => {
            console.error(err);
            getRollbar().error(`ConfirmSetAutopayMethodStep: Received an error response when setting Autopay on method ${stepData.token}. 
                Response: ${JSON.stringify(err)}`);
            onError();
        });
    };

    return (
        <Fragment>
            <Modal.Header>
                <h3 className="modal-title">
                    Do you want to enroll this payment method in Auto Pay?
                </h3>
                <button className="btn-close" onClick={onHide} disabled={isProcessing}></button>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Click <strong>Yes</strong> to set this as your default payment used for monthly Auto Pay. By enrolling in Auto Pay, you agree to all Terms & Conditions.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleSetMethod} disabled={isProcessing}>
                    {isProcessing && <span className="js-spinner spinner spinner-border align-middle me-1"></span>}
                    Yes, enroll in Auto Pay
                </Button>
                <Button variant="secondary" onClick={onHide} disabled={isProcessing}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Fragment>
    );
}

export default ConfirmSetAutopayMethodStep;