import React from "react";
import DeletePaymentMethodModal from "./Modals/DeletePaymentMethodModal";
import GenericErrorModal from "./Modals/GenericErrorModal";
import MultiStepModal from "./Modals/MultiStepModal";
import SetDefaultPaymentMethodModal from "./Modals/SetDefaultPaymentMethodModal";
import ConfirmAddPaymentMethodStep from "./Steps/ConfirmAddPaymentMethodStep";
import SelectPaymentTypeStep from "./Steps/SelectPaymentTypeStep";
import { usePaymentMethods, usePaymentMethodsDispatch } from "./Contexts/PaymentMethodsContext";
import { useModals } from "./Contexts/ModalContext";

const PaymentMethodsTable = () => {
    const modals = useModals();
    const paymentMethodsContext = usePaymentMethods();
    const paymentMethodsDispatch = usePaymentMethodsDispatch();

    const showSetDefaultModal = (selectedMethod) => {
        const setDefaultModal = <SetDefaultPaymentMethodModal centered backdrop="static" keyboard={false} selectedMethod={selectedMethod} 
            onFinished={handleDefaultMethodSet} />
        
        modals.show(setDefaultModal)
    }

    const showDeleteModal = (selectedMethod) => {
        const deleteModal = <DeletePaymentMethodModal centered backdrop="static" keyboard={false} selectedMethod={selectedMethod}
            methods={paymentMethodsContext.methods} onFinished={handleDeletedPaymentMethod} />

        modals.show(deleteModal)
    }

    const showAddModal = () => {
        const addModal = <MultiStepModal centered backdrop="static" keyboard={false} onFinished={handleAddedPaymentMethod} >
            <SelectPaymentTypeStep />
            <ConfirmAddPaymentMethodStep showSaveOnly={true} />
        </MultiStepModal>

        modals.show(addModal);
    }

    const showErrorModal = () => {
        modals.show(<GenericErrorModal centered />);
    }

    const handleAddedPaymentMethod = (result) => {
        if (result.hadError){
            showErrorModal();
            return;
        }

        if (!result.wasCancelled)
            paymentMethodsDispatch({
                type: 'added', 
                methods: result.data
            });
    }

    const handleDeletedPaymentMethod = (result) => {
        if (result.hadError){
            showErrorModal();
            return;
        }

        if (!result.wasCancelled)
            paymentMethodsDispatch({
                type: 'deleted', 
                methods: result.methods
            });
    }

    const handleDefaultMethodSet = (result) => {
        if (result.hadError){
            showErrorModal();
            return;
        }

        if (!result.wasCancelled)
            paymentMethodsDispatch({
                type: 'updated', 
                method: result.defaultMethod
            });
    }

    return (
        <section className="account-information payment-methods">
            <h3>Manage Payment Methods</h3>
            {paymentMethodsContext.loading && <div className="methods-table-loader">
                <i className="js-spinner spinner spinner-border align-middle"></i>
            </div>}
            {(!paymentMethodsContext.loading && paymentMethodsContext.methods.length > 0) &&
                <div className="methods-table">
                    <div className="methods-table-head">
                        <div className="methods-table-row">
                            <div className="methods-table-column"></div>
                            <div className="methods-table-column">Account Name</div>
                            <div className="methods-table-column">Ending In</div>
                            <div className="methods-table-column">Expires</div>
                            <div className="methods-table-column">Remove</div>
                        </div>
                    </div>
                    <div className="methods-table-body">
                        {paymentMethodsContext.methods.map((method) =>
                            <div key={method.token} className="methods-table-row">
                                <div className="methods-table-column">
                                    <input type="radio" name="defaultMethod" checked={method.isDefault}
                                        onChange={() => showSetDefaultModal(method)} />
                                    {method.isDefault && <span className="default-method">default</span>}
                                </div>
                                <div className="methods-table-column">
                                    <span className="mobile-label">Account Name </span>
                                    {method.name}
                                </div>
                                <div className="methods-table-column">
                                    <span className="mobile-label">Ending In </span>
                                    {method.cardEnd}
                                </div>
                                <div className="methods-table-column">
                                    <span className="mobile-label">Expires </span>
                                    {method.expirationDate}
                                </div>
                                <div className="methods-table-column">
                                    <a href="#" className="js-remove-payment-method text-decoration-none" onClick={() => showDeleteModal(method)}>
                                        <i className="de-bin"></i>
                                        <span className="mobile-label">Remove</span>
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            }
            {!paymentMethodsContext.loading &&
                <div className="add-payment-method add-new">
                    <a href="#" className="js-add-new-payment-method" onClick={showAddModal}><i className="de-add"></i>Add New Payment Method</a>
                </div>
            }
        </section>
    );
}

export default PaymentMethodsTable;