import React, { Fragment } from "react";
import { useModals } from "../Contexts/ModalContext";

const ModalRoot = () => {
    const modalContext = useModals();

    return (
        <Fragment>
            {modalContext.component 
                ? React.cloneElement(modalContext.component , {
                    ...modalContext.component.props,
                    show: !modalContext.isHidden,
                    onHide: modalContext.hide,
                    onFinished: (result) => { 
                        modalContext.exit();
                        if(modalContext.component.props.onFinished != null)
                            modalContext.component.props.onFinished(result);
                    }
                })
                : null
            }
        </Fragment>
    )
}

export default ModalRoot