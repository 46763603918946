import React, { Fragment, useState } from "react";
import { Modal, Button } from 'react-bootstrap'
import axios from 'axios';
import getRollbar from "../../globals/rollbar";

const ConfirmAddPaymentMethodStep = ({ stepData, showSaveOnly, onHide, onError, onFinished }) => {
    const [requestStatus, setRequestStatus] = useState({
        inFlight: false,
        enroll: false,
    });

    const handleSavePayment = (enroll) => {
        setRequestStatus({
            inFlight: true,
            enroll: enroll
        });

        axios.post('/api/paymentmethods/add', {
            accountName: stepData.accountName,
            token: stepData.token,
            expiryMonth: stepData.expiryMonth,
            expiryYear: stepData.expiryYear,
            number: stepData.number,
            type: stepData.type,
            enableAutoPay: enroll
        }).then(res => {
            onFinished({
                wasMethodAdded: true,
                data: res.data
            });
        }).catch(err => {
            console.error(err);
            getRollbar().error(`ConfirmAddPaymentMethodStep: Received an error response when adding payment method ${JSON.stringify(stepData)}. 
                Response: ${JSON.stringify(err)}`);
            onError();
        });
    };

    return (
        <Fragment>
            <Modal.Header>
                <h3 className="modal-title">
                    Do you want to enroll this payment method in Auto Pay?
                </h3>
                <button className="btn-close" onClick={onHide} disabled={requestStatus.inFlight}></button>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Click <strong>Yes</strong> to set this as your default payment used for monthly Auto Pay. By enrolling in Auto Pay, you agree to all Terms & Conditions.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => handleSavePayment(true)} disabled={requestStatus.inFlight}>
                    {requestStatus.inFlight && requestStatus.enroll && <span className="js-spinner spinner spinner-border align-middle me-1"></span>}
                    Yes, enroll in Auto Pay
                </Button>
                {showSaveOnly && 
                    <Button variant="primary" onClick={() => handleSavePayment(false)} disabled={requestStatus.inFlight}>
                        {requestStatus.inFlight && !requestStatus.enroll && <span className="js-spinner spinner spinner-border align-middle me-1"></span>}
                        Save Payment Only
                    </Button>
                }
                <Button variant="secondary" onClick={onHide} disabled={requestStatus.inFlight}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Fragment>
    );
}

export default ConfirmAddPaymentMethodStep;