import React, { useState } from "react";
import { Modal, Button } from 'react-bootstrap'
import axios from 'axios';
import getRollbar from "../../globals/rollbar";

const TurnOffAutoPayModal = ({ selectedMethod, onHide, onFinished, ...props }) => {
    const [isProcessing, setIsRequestInFlight] = useState(false);
    const [result, setResult] = useState({
        hadError: false,
        wasCancelled: true,
        method: null
    });

    const handleConfirm = () => {
        setIsRequestInFlight(true);

        axios.post('/api/paymentmethods/setautopay', {
            autopayEnabled: false,
            walletId: selectedMethod.token,
            action: "off"
        }).then(res => {
            setResult({
                method: res.data,
                wasCancelled: false,
                hadError: false
            });
        }).catch(err => {
            console.error(err);
            getRollbar().error(`TurnOffAutoPayModal: Received an error response when setting method ${selectedMethod.token}. 
                Response: ${JSON.stringify(err)}`);
            setResult({
                method: null,
                wasCancelled: false,
                hadError: true
            });
        }).finally(() => onHide());
    }

    const handleExited = () => {
        setIsRequestInFlight(false);
        onFinished(result);
    }

    return (
        <Modal
            {...props}
            className="payment-and-billing-modal"
            onExited={handleExited}
        >
            <Modal.Header>
                <h3 className="modal-title">
                    Turn Off Auto Pay?
                </h3>
                <button className="btn-close" onClick={onHide} disabled={isProcessing}></button>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to turn off Auto Pay?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleConfirm} disabled={isProcessing}>
                    {isProcessing && <span className="js-spinner spinner spinner-border align-middle me-1"></span>}
                    Yes, turn off Auto Pay
                </Button>
                <Button variant="secondary" onClick={onHide} disabled={isProcessing}>
                    No, keep it on
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default TurnOffAutoPayModal;