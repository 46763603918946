import React from "react";
import ReactDOM from "react-dom";
import PaperlessBilling from "./PaperlessBillingComponent";
import PaymentMethods from "./PaymentMethodsComponent";
import ModalRoot from "./Modals/ModalRoot";
import { PaymentMethodsProvider } from "./Contexts/PaymentMethodsContext";
import { PaymentTypesContext } from "./Contexts/PaymentTypesContext";
import { ModalProvider } from "./Contexts/ModalContext";
import axios from "axios";
import { ErrorBoundary } from "./Boundaries/ErrorBoundary";

const wrapperNode = document.getElementById('react-payment-billing');
if (wrapperNode !== null) {
    //intercept 401 and force a reauthentication, because we
    axios.interceptors.response.use((response) => response, 
        (error) => {
            if(error.response.status === 401)
                window.location.reload();

            return Promise.reject(error);
    });

    const paymentTypes = [
        {
            id: 'cc',
            name: 'Credit Card',
            iframeUrl: wrapperNode.dataset.paymentUrlCc
        },
        {
            id: 'ach',
            name: 'ACH / Bank Account',
            iframeUrl: wrapperNode.dataset.paymentUrlAch,
        }
    ];

    ReactDOM.render(
        <React.StrictMode>
            <ErrorBoundary>
                <PaymentMethodsProvider>
                    <PaymentTypesContext.Provider value={paymentTypes}>
                        <ModalProvider>
                            <PaymentMethods />
                            <PaperlessBilling showToggle={wrapperNode.dataset.showPaperlessToggle === "true"} enabled={wrapperNode.dataset.paperlessInitial === "true"} />
                            <ModalRoot />   
                        </ModalProvider>
                    </PaymentTypesContext.Provider>
                </PaymentMethodsProvider>
            </ErrorBoundary>
        </React.StrictMode>,
        wrapperNode
    );
}
