import React, { useState } from "react";
import { Modal, Button } from 'react-bootstrap'
import axios from 'axios';
import getRollbar from "../../globals/rollbar";

const SetDefaultPaymentMethodModal = ({ selectedMethod, onHide, onFinished, ...props }) => {
    const [isProcessing, setIsProcessing] = useState(false);
    const [result, setResult] = useState({
        hadError: false,
        wasCancelled: true,
        defaultMethod: null
    });

    const handleConfirm = () => {
        setIsProcessing(true);

        axios.put('/api/paymentmethods/setdefault', {
            walletId: selectedMethod.token
        }).then(res => {
            setResult({
                defaultMethod: res.data,
                wasCancelled: false,
                hadError: false
            });
        }).catch(err => {
            console.error(err);
            getRollbar().error(`SetDefaultPaymentMethodModal: Received an error response when setting method ${selectedMethod.token}. 
                Response: ${JSON.stringify(err)}`);
            setResult({
                defaultMethod: null,
                wasCancelled: false,
                hadError: true
            });
        }).finally(() => onHide());
    }

    const handleExited = () => {
        setIsProcessing(false);
        onFinished(result);
    }

    return (
        <Modal
            {...props}
            className="payment-and-billing-modal"
            onExited={handleExited}
        >
            <Modal.Header>
                <h3 className="modal-title">
                    Are you sure you want to update your default payment?
                </h3>
                <button className="btn-close" onClick={onHide} disabled={isProcessing}></button>
            </Modal.Header>
            <Modal.Body>
                <p>Your default payment is used for monthly Auto Pay.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleConfirm} disabled={isProcessing}>
                    {isProcessing && <span className="js-spinner spinner spinner-border align-middle me-1"></span>}
                    Yes, update default
                </Button>
                <Button variant="secondary" onClick={onHide} disabled={isProcessing}>
                    No, keep default
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default SetDefaultPaymentMethodModal;