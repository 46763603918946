import * as icheck from "../../icheck/icheck";

let initialized = false;
let callbacks = [];

const dispatch = (data) =>
{
    let paymentMethod = null;
    const validationResult = icheck.responseToValidationData(data);
    
    if(validationResult.approved) {
        const expirationDate = data.cardExpDate && icheck.expirationDateFrom(data.cardExpDate);
        paymentMethod = {
            token: data.token,
            accountName: icheck.accountNameFor(data),
            expiryMonth: data.cardExpDate && expirationDate.month,
            expiryYear: data.cardExpDate && expirationDate.year,
            number: data.operation === "SaveACHToken"
                ? data.accountNumber
                : data.cardNumber,
            type: data.operation === "SaveACHToken"
                ? "ach"
                : "cc"
        };
    }

    for(const callback of callbacks) {
        callback({
            rawData: data,
            validationResult: validationResult,
            denialReason: icheck.denialReasonFor(validationResult),
            paymentMethod: paymentMethod
        })
    }
}

export const initialize = () => {
    if(!initialized) {
        console.log('ICheckEventEmitter initialized');
        icheck.initICheckIFrameEvents(dispatch);
        initialized = true;
    }
}

export const subscribe = (callback) => 
    callbacks = [...callbacks, callback];  

export const unsubscribe = (callback) =>
    callbacks = callbacks.filter(existingCallback => existingCallback != callback);