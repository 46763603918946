import React, { createContext, useContext, useReducer, useState, useEffect } from 'react';
import { initialize as initializeICheck }  from '../ICheck/ICheckEventEmitter';
import axios from 'axios';

const PaymentMethodsContext = createContext(null);
const PaymentMethodsDispatchContext = createContext(null);

export const PaymentMethodsProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [paymentMethods, dispatch] = useReducer(
        paymentMethodsReducer,
        {
            methods: [],
            autopayMethod: null,
            defaultMethod: null
        }
    );

    useEffect(() => {
        initializeICheck();
        axios.get('/api/paymentmethods').then(res => {
            dispatch({
                type: 'loaded',
                methods: res.data
            });
            setIsLoading(false);
        }).catch(err => {
            console.error('Could not load payment methods', err);
            throw new Error(`PaymentMethodsProvider: Failed to load payment methods. Received: ${JSON.stringify(err)}`);
        });
    }, []);

    return (
        <PaymentMethodsContext.Provider value={{
            loading: isLoading,
            methods: paymentMethods.methods,
            autopayMethod: paymentMethods.autopayMethod,
            defaultMethod: paymentMethods.defaultMethod
        }}>
            <PaymentMethodsDispatchContext.Provider value={dispatch}>
                {children}
            </PaymentMethodsDispatchContext.Provider>
        </PaymentMethodsContext.Provider>
    );
}

export const usePaymentMethods = () =>
    useContext(PaymentMethodsContext);

export const usePaymentMethodsDispatch = () => 
    useContext(PaymentMethodsDispatchContext)
    
const paymentMethodsReducer = (paymentMethods, action) => {
    switch (action.type) {
        case 'loaded':
        case 'added':
        case 'deleted': {
            return {
                methods: action.methods,
                defaultMethod: action.methods.find(x => x.isDefault) || null,
                autopayMethod: action.methods.find(x => x.isAutopay) || null
            }
        }
        case 'updated': {
            return {
                methods: paymentMethods.methods.map(method => {
                    if (method.token != action.method.token) {
                        method.isDefault = false;
                        method.isAutopay = false;
                        return method;
                    }

                    return action.method;
                }),
                defaultMethod: action.method,
                autopayMethod: action.method.isAutopay
                    ? action.method
                    : null
            }
        }
        default: {
            throw Error(`paymentMethodsReducer: Unknown action ${action.type}`);
        }
    }
}