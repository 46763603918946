import React, { Fragment } from "react";
import { Modal, Button } from 'react-bootstrap'

const AutopaySelectMethodStep = ({ onSelected, onCreate, onHide, methods }) => {
    const handleMethodSelected = (event) => {   
        const selectedMethod = methods.find(method => method.token === event.target.value);
        onSelected(selectedMethod);
    }

    return (
        <Fragment>
            <Modal.Header>
                <h3 className="modal-title">
                    Choose a Payment Method or Add a New One
                </h3>
                <button className="btn-close" onClick={onHide}></button>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Choose one of your existing payment methods to use with <strong>AutoPay</strong> or add a new one.
                </p>
                <div className="form-group">
                    {methods != null && 
                        <select className="form-control form-select" defaultValue="none" onChange={handleMethodSelected}>
                            <option value="none" disabled>Select Payment Method</option>
                            {methods.map((method) =>
                                <option key={method.token} value={method.token}>{method.name}</option>
                            )}
                        </select>
                    }
                    <div className="add-payment-method add-new mt-4">
                        <a href="#" className="js-add-new-payment-method" onClick={onCreate}><i className="me-2 de-add"></i>Add a Payment Method</a>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Fragment>
    );
}

export default AutopaySelectMethodStep;