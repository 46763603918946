import React, { useState } from "react";
import { Modal } from 'react-bootstrap'

const MultiStepModal = ({ onHide, onFinished, children, ...props }) => {
    const [currentStep, setCurrentStep] = useState({
        index: 0,
        stepData: null
    });

    const [result, setResult] = useState({
        wasCancelled: true,
        hadError: false,
        data: null
    });

    const handleHide = () => 
        onHide();

    const handleExited = () => 
        onFinished(result);

    const handleNext = (newData) => {
        setCurrentStep({
            index: currentStep.index + 1,
            stepData: newData
        });
    }

    const handleFinished = (result) => {
        setResult({
            wasCancelled: false,
            hadError: false,
            data: result.data
        });

        onHide();
    }

    const handleStepError = () => {
        setResult({
            wasCancelled: false,
            hadError: true,
            data: null
        });

        onHide();
    }

    return (
        <Modal
            {...props}
            className="payment-and-billing-modal"
            onExited={handleExited}
        >
            {React.cloneElement(children[currentStep.index], {
                stepData: currentStep.stepData,
                onHide: handleHide,
                onNext: handleNext,
                onError: handleStepError,
                onFinished: handleFinished,
                ...children[currentStep.index].props
            })}
        </Modal>
    );
}

export default MultiStepModal;